import { EffectFade, Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"

import 'swiper/css/effect-fade'
import 'swiper/css/pagination'
import { Helmet } from "react-helmet"

function Rukava() {

    const slides = [
        {
            src: "/assets/images/marine.png",
            text: "Гидравлические рукава"
        },
        {
            src: "/assets/images/metal.png",
            text: "Металлорукава"
        },
        {
            src: "/assets/images/prom.png",
            text: "Промышленные рукава"
        },
    ]

    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
          return '<span class="' + className + '">' + (slides[index].text) + '</span>';
        },
      };

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Восток Марин Сервис Инжинеринг</title>
                <meta name='description' content='Производство и поставка рукавов высокого давления'/>
                <link rel="canonical" href="https://vostokmarineservice.com/rukava" />
            </Helmet>
            
            <section className="ftco-section rukava-hero-bg">
                <div className="image-filter">
                    <div className="ftco-section container">
                        <div className="row no-gutters">
                            <div className="col-lg-8 offset-lg-2 col-sm-12 text-center">
                                <h1 className="color-white">Рукава и фитинги</h1>
                                <p className="color-white">Производим рукава высокого давления с испытанием давлением 2xWP, сервис на объектах заказчика, аудит, обучение и оказываем техническую поддержку.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-8 offset-lg-2 center mb-5">
                            <h2 className="text-center mb-5">Производство и поставка рукавов высокого давления</h2>
                        </div>
                        <div className="col-lg-8 offset-lg-2 col-sm-12 center">
                            <Swiper id="rukava-swiper"
                                modules={[EffectFade, Pagination]}
                                pagination={pagination}
                                effect={'fade'}
                                speed={2000}
                                loop={false}
                                spaceBetween={0}
                                slidesPerView={1}
                            >
                                {slides.map((slide) => (
                                    <SwiperSlide key={slide.text}>
                                        <img style={{ width: "100%" }} src={`${slide.src}`} alt="" />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="ftco-section bg-blue">
                <div className="container">
                    <div className="row justify-content-start align-items-center">
                        <div className="col-lg-4 offset-md-1 heading-section">
                            <h2 className="mb-4 text-white">Мобильная мастерская по производству и испытанию РВД, на базе контейнера</h2>
                            <p className="text-white">Обжимной станок O+P Tubomatic H144 EL<br/>
                            Обжимной станок Finn-Power P16HP<br/>
                            Испытательный стенд BC 1200 ECO<br/>
                            Окорочный станок SPF2/E</p>
                        </div>
                        <div className="col-lg-6 pl-md-5 heading-section">
                            <div className="pl-md-4 border-line">
                                <div className="col-md-12">
                                    <div className="blog-entry">
                                        <img src="assets/images/mobile-station.png" style={{ width: "100%"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-8 offset-lg-2 col-sm-12 center">
                            <h2 className="mb-4 text-center">Подбор аналогов и поставка оригинальных позиций для
                            специализированного оборудования и техники</h2>
                            <img src="assets/images/brands.png" style={{ width: "100%"}}/>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contacts" className="ftco-section contact-section">
                <div className="container">
                    <div className="row d-flex gap-4 mb-5 contact-info">
                        <div className="col-md-12 heading-section mb-4">
                                    <span className="subheading subheading-with-line"><small
                                                className="pr-2 bg-white">Контакты</small></span>
                            <h2 className="h4">Связаться с нами</h2>
                        </div>
                        <div className="col-md-4">
                            <p><span>Адрес:<br/></span> 693000, Сахалинская область, г. Южно-Сахалинск, ул. Транзитная 19</p>
                        </div>
                        <div className="col-md-2">
                            <p><span>Телефон:<br/></span> <a href="tel://84242466067">8 (4242) 46-60-67</a></p>
                        </div>
                        <div className="col-md-2">
                            <p><span>Факс:<br/></span> <a href="tel://84242466068">8 (4242) 46-60-68</a></p>
                        </div>
                        <div className="col-md-4">
                            <p><span>Email:<br/></span> <a href="mailto:accounting@vostokmarineservice.com">accounting@vostokmarineservice.com</a><br/><a
                                        href="mailto:supply@vostokmarineservice.com">supply@vostokmarineservice.com</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Rukava
import './App.css';
import {Outlet, Link, useLocation} from 'react-router-dom';

function App() {
    let location = useLocation();

    console.log(location.pathname);

  return (
    <>

      <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar">
        <div className="container">
          <Link className="navbar-brand" to="/">
              <img
                  className="header-logo"
                  src={'assets/images/' + (location.pathname === '/rukava' ? 'vms-i-logo.svg' : 'vms-logo.svg')}
                  alt="Восток Марин Сервис"
              /></Link>
          <div className="navbar-collapse" id="ftco-nav">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item"><Link to="/" className="nav-link">Главная</Link></li>
              <li className="nav-item"><Link to="rukava" className="nav-link">Рукава и фитинги</Link></li>
              <li className="nav-item"><Link to="regioni" className="nav-link">Регионы: Устойчивое развитие</Link></li>
              <li className="nav-item"><a href="#contacts" className="nav-link">Контакты</a></li>
            </ul>
          </div>
        </div>
      </nav>

      <Outlet />

      <footer className="ftco-footer ftco-bg-dark ftco-section">
        <div className="container">
          <div className="row">
              <div className="col-md-12 text-center">
                <p>Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script> ООО "Восток Марин Сервис"<br/>
                    Дизайн компании <a href="https://colorlib.com" target="_blank">Colorlib. </a>
                    Сайт разработан <a href="https://gigonom.com" target="_blank">ООО "Гигоном"</a>
                </p>
              </div>
          </div>
      </div>
      </footer>

      <script src="assets/js/jquery.min.js"></script>
      <script src="assets/js/jquery-migrate-3.0.1.min.js"></script>
      <script src="assets/js/popper.min.js"></script>
      <script src="assets/js/bootstrap.min.js"></script>
      <script src="assets/js/jquery.easing.1.3.js"></script>
      <script src="assets/js/jquery.waypoints.min.js"></script>
      <script src="assets/js/jquery.stellar.min.js"></script>
      <script src="assets/js/owl.carousel.min.js"></script>
      <script src="assets/js/jquery.magnific-popup.min.js"></script>
      <script src="assets/js/aos.js') }}"></script>
      <script src="assets/js/jquery.animateNumber.min.js"></script>
      <script src="assets/js/bootstrap-datepicker.js"></script>
      <script src="assets/js/scrollax.min.js"></script>
      <script src="assets/js/main.js"></script>
    </>
  );
}

export default App;

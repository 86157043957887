import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'
import { useState } from "react";
import { Helmet } from "react-helmet";

function Main() {

    const [showed, setShowed] = useState(false);
    function showDescription() {
        setShowed(!showed)
    }

  return (
    <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Восток Марин Сервис</title>
      <meta name='description' content='Профессиональное нефтегазовое снабжение'/>
      <link rel="canonical" href="https://vostokmarineservice.com/" />
    </Helmet>
    
      <section className="ftco-section pt-0 pb-0 bg-light">
        <div className="container">
            <div className="row no-gutters">
                <div className="swiper-container col-md-6">
                        <Swiper id='main-swiper' className="swiper-wrapper"
                            modules={[Autoplay, EffectFade]}
                            autoplay={{
                              delay: 2000,
                              disableOnInteraction: false,
                            }}
                            effect={'fade'}
                            speed={2000}
                            loop={true}
                            spaceBetween={0}
                            slidesPerView={1}
                        >
                            <SwiperSlide className="swiper-slide img img-2 bg-1"></SwiperSlide>
                            <SwiperSlide className="swiper-slide img img-2 bg-2"></SwiperSlide>
                            <SwiperSlide className="swiper-slide img img-2 bg-3"></SwiperSlide>
                            <SwiperSlide className="swiper-slide img img-2 bg-4"></SwiperSlide>
                            <SwiperSlide className="swiper-slide img img-2 bg-5"></SwiperSlide>
                            <SwiperSlide className="swiper-slide img img-2 bg-6"></SwiperSlide>
                            <SwiperSlide className="swiper-slide img img-2 bg-7"></SwiperSlide>
                        </Swiper>
                </div>

                <div className="col-md-6 wrap-about pb-md-5 mt-5">
                    <div className="heading-section mb-5 pl-md-5">
                        <div className="pl-md-5 ml-md-5">
                            <span className="subheading subheading-with-line">
                                <small  className="pr-2 bg-white">О компании</small>
                            </span>
                            <h2 className="mb-4">Восток Марин Сервис &mdash; Профессиональное Нефтегазовое Снабжение</h2>
                        </div>
                    </div>
                    <div className="pl-md-5 ml-md-5 mb-5">
                        <p>Компания ООО «Восток Марин Сервис» работает с 2011 года. Основное направление - обеспечение
                            жизнедеятельности ППБУ, в процессе строительства скважин на шельфе, а также предоставление
                            агентских и логистических услуг для компаний нефтегазовой отрасли.</p>
                    </div>
                    {showed &&

                        <div id="showMore" className="pl-md-5 ml-md-5 mb-5">
                        <p>Компания «Восток Марин Сервис» с 2020 года является регональным представителем Конкурса "РЕГИОНЫ Устойчивое развитие".</p>
                        <p>С 2014 года произведены первые вложения в создание материально-технической базы. С 2017 года
                            одним из наших приоритетных направлений является обслуживание и ремонт Тяжёлого пром
                            оборудования, а также предоставление подводно-технических сервисов с использованием специальной
                            гидравлической техники.</p>
                        <p>В целях объединения усилий по дальнейшему развитию направления подводно-технических сервисов, в
                            2018 году создан совместный консорциум с ООО «ПетроГазТех».</p>
                        <p>С 2018 года проводятся совместные работы по проекту Сахалин 2. В настоящее время мы активно
                            развиваем наземную базу по ремонту и обслуживанию гидрографического оборудования и гравлиических
                            машин. Имеем собственный цех по изготовлению рукавов высокого давления. В среднесрочной
                            программе развития ООО «Восток Марин Сервис» планирует дальнейшее расширение собственного парка
                            оборудования и технических сервисов, для нужд компаний нефтегазовой отрасли.</p>
                    </div>
                    }
                    
                    <div className="pl-md-5 ml-md-5 mb-5">
                        <p><a href="#" type="button" onClick={showDescription} className="btn-custom">Ещё о компании <span className="ion-ios-arrow-forward"></span></a></p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="partners" className="ftco-section ftco-client pt-0 pb-5 bg-light">
        <div className="container">
            <div className="row justify-content-end mb-5 pb-2">
                <div className="col-md-4 heading-section text-right">
                    <span className="subheading subheading-with-line"><small className="pr-2 bg-white">Наши партнеры</small></span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <Swiper className="clients-swiper"
                        slidesPerView={5}
                        spaceBetween={40}
                        breakpoints={{
                            320: {
                              slidesPerView: 2,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 30,
                            },
                            1024: {
                              slidesPerView: 5,
                              spaceBetween: 40,
                            },
                          }}
                    >
                        <SwiperSlide className="clients-item">
                            <img className="client text-center p-5" src="assets/images/logo-regioni.png" alt=""></img>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img className="client text-center p-5" src="assets/images/spec.png" alt=""></img>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img className="client text-center p-5" src="assets/images/cortem.png" alt=""></img>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img className="client text-center p-5" src="assets/images/drill.png" alt=""></img>
                        </SwiperSlide>
                        <SwiperSlide className="item">
                            <img className="client text-center p-5" src="assets/images/parker.png" alt=""></img>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    </section>

    <section id="news" className="ftco-section">
        <div className="container">
            <div className="row justify-content-start">
                <div className="col-md-4 heading-section">
                            <span className="subheading subheading-with-line"><small
                                        className="pr-2 bg-white">Новости</small></span>
                    <h2 className="mb-4">Главная новость</h2>
                </div>
                <div className="col-md-8 pl-md-5 heading-section">
                    <div className="pl-md-4 border-line">

                        <div className="col-md-12">
                            <div className="blog-entry">
                                <Link to={"regioni"} className="block-20"><img src="assets/images/regioni.png" style={{ width: "100%"}}/></Link>
                                <div className="text d-flex py-4">
                                    <div className="meta mb-3">
                                        <div><a href="#">30 июня 2020</a></div>
                                    </div>
                                    <div className="desc pl-3">
                                        <h3 className="heading"><a href="{{ url('regioni') }}">Кредиты на строительство и реконструкцию
                                                жилой и коммерческой недвижимости</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="services" className="ftco-services bg-light">
        <div className="container">
            <div className="row justify-content-start py-5">
                <div className="col-md-4 heading-section">
                    <span className="subheading subheading-with-line"><small className="pr-2 bg-white">Услуги</small></span>
                    <h2 className="mb-4">Что мы предлагаем?</h2>
                </div>
                <div className="col-md-8 pl-md-5">
                    <div className="pl-md-4 border-line">
                        <div className="row">
                            <div className="col-md-4 d-flex align-self-stretch">
                                <div className="media block-6 services d-block">
                                    <div className="media-body p-2">
                                        <h3 className="heading">Товары</h3>
                                        <p>Поставки товаров любой сложности</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex align-self-stretch">
                                <div className="media block-6 services d-block">
                                    <div className="media-body p-2">
                                        <h3 className="heading">Логистика</h3>
                                        <p>Транспортная, таможенная, морская, складская, ответственное хранение</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex align-self-stretch">
                                <div className="media block-6 services d-block">
                                    <div className="media-body p-2">
                                        <h3 className="heading">Агентские услуги</h3>
                                        <p>Катеринг, встречи и проводы персонала, логистические услуги(авиабилеты, ж/д
                                            билеты, гостиницы), визовая поддержка, подбор персонала</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 d-flex align-self-stretch">
                                <div className="media block-6 services d-block">
                                    <div className="media-body p-2">
                                        <h3 className="heading">Комплекс</h3>
                                        <p>Комплексное обслуживание буровых подрядчиков в соответствии с международными
                                            стандартами (обеспечение жизнедеятельности ППБУ, СПБУ, флота снабжения в
                                            процессе производства буровых работ на шельфе РФ и за рубежом)
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="products" className="ftco-services">
        <div className="container">
            <div className="row justify-content-start py-5">
                <div className="col-md-4 heading-section">
                            <span className="subheading subheading-with-line"><small
                                        className="pr-2 bg-white">Продукция</small></span>
                    <h2 className="mb-4">Что мы поставляем?</h2>
                </div>
                <div className="col-md-8">
                    <div className="row d-flex">
                        <div className="col-md-12 d-flex align-items-stretch">
                            <div className="media block-6 services services-2 d-block bg-light p-4 mb-4">
                                <div className="icon d-flex justify-content-center align-items-center">
                                    <span className="flaticon-layers"></span>
                                </div>
                                <div className="media-body p-2 mt-3">
                                    <h3 className="heading">All Types of Valves Supplied:</h3>
                                    <p>Ball, Gate, Globe, Check, Butterfly, Through Conduit Gates, Control, PSV,
                                        Needle
                                        and
                                        Double Block & Bleed, Cryogenic, Manual & Actuated, Topside & Subsea</p>
                                    <h3 className="heading">Ball Valves</h3>
                                    <p>JC, Kitz, Dafram, Della Foglia, Sri, Starline, Velan, PBV, Grove, Perar,
                                        Piper,
                                        Vinco, Zavero, Moma, KF, NSSL, John Mills, Shipham, JC, Sern, Pekos</p>
                                    <h3 className="heading">Gates & Globes</h3>
                                    <p>LVF, OMB, Calobri, Bonney Forge, Douglas Chero, Fluval, BAF, Tocina, Kitz,
                                        Firsa,
                                        Valvosider, Valitalia, DSI, Velan, John Mills, Shipham</p>
                                    <h3 className="heading">Butterfly Valves & Plugs</h3>
                                    <p>Charles Winn, Vanessa, Intervalve, Tomoe, Ebro, Hobbs, Bray, Keystone,
                                        Saunders,
                                        Audco, Hindle</p>
                                    <h3 className="heading">Double Block & Bleed</h3>
                                    <p>Alco, Oliver, Saccap, Moma, Parker, Colson</p>
                                    <h3 className="heading">Check</h3>
                                    <p>Abacus, Goodwin, Crane, LVF, OMB, Calobri, Bonney Forge, Douglas chero,
                                        Fluval,
                                        BAF, Tocina, Kitz, Firsa, Valvosider, Valitalia, DSI, Velan, Shipham, John
                                        Mills, Mokveld, Entech</p>
                                    <h3 className="heading">Pressure Safety Valves</h3>
                                    <p>Pentair, Broady, Leser</p>
                                    <h3 className="heading">Control & Choke Valves</h3>
                                    <p>Koso Kent Introl, Tomoe Tritec, Taylor, Claval, Parcol</p>
                                    <h3 className="heading">All Major Sizes</h3>
                                    <p>1/2 to 48</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-stretch">
                            <div className="media block-6 services services-2 d-block bg-light p-4 mb-4">
                                <div className="icon d-flex justify-content-center align-items-center">
                                    <span className="flaticon-layers"></span>
                                </div>
                                <div className="media-body p-2 mt-3">
                                    <h3 className="heading">Batteries</h3>
                                    <p>EXIDE (GNB), Varta, Panasonic, APC, Power Sonic, Rocket, FIAMM</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-stretch">
                            <div className="media block-6 services services-2 d-block bg-light p-4 mb-4">
                                <div className="icon d-flex justify-content-center align-items-center">
                                    <span className="flaticon-layers"></span>
                                </div>
                                <div className="media-body p-2 mt-3">
                                    <h3 className="heading">Filtration</h3>
                                    <p>Parker Hannifin, Indufil, DONALDSON, Eaton, Fläkt Woods, Filtra Systems,
                                        CAMFIL,
                                        Baldwin filters, Filterec, Mann filters, Separ filter</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-stretch">
                            <div className="media block-6 services services-2 d-block bg-light p-4 mb-4">
                                <div className="icon d-flex justify-content-center align-items-center">
                                    <span className="flaticon-layers"></span>
                                </div>
                                <div className="media-body p-2 mt-3">
                                    <h3 className="heading">Explosion Proof Equipment</h3>
                                    <p>Cortem Group, Chalmit, Bartec, Wolf Safety, ВЭЛАН, Завод Горэлтех, Atexxo
                                        Manufacturing, Medc, R.Stahl, Pelican Products, Wolf Safety, Eaton,
                                        Hadef</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-stretch">
                            <div className="media block-6 services services-2 d-block bg-light p-4 mb-4">
                                <div className="icon d-flex justify-content-center align-items-center">
                                    <span className="flaticon-layers"></span>
                                </div>
                                <div className="media-body p-2 mt-3">
                                    <h3 className="heading">Instrument and measuring equipment</h3>
                                    <p>Midtronics, Fluke, Phoenix Contact, Bartec, Wika, Load Scan, Omron, Elemer,
                                        Ashcroft, Fireye, DRAGER</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 d-flex align-items-stretch">
                            <div className="media block-6 services services-2 d-block bg-light p-4 mb-4">
                                <div className="icon d-flex justify-content-center align-items-center">
                                    <span className="flaticon-layers"></span>
                                </div>
                                <div className="media-body p-2 mt-3">
                                    <h3 className="heading">Tools and equipment</h3>
                                    <p>3x, Bosch, Hilti, Stahlwille, KSTOOLS, Hitachi, Makita, Husqvarna, RENNSTEIG,
                                        Metabo, CLIMAX, PLARAD, PROTEM, SERCO, HAZET, INGERSOLL RAND, Momento,
                                        Enerpac,
                                        Chicago Pneumatic, Pipe equipment specialists Ltd, DRAGER</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section id="contacts" className="ftco-section contact-section">
                <div className="container">
                    <div className="row d-flex gap-4 mb-5 contact-info">
                        <div className="col-md-12 heading-section mb-4">
                                    <span className="subheading subheading-with-line"><small
                                                className="pr-2 bg-white">Контакты</small></span>
                            <h2 className="h4">Связаться с нами</h2>
                        </div>
                        <div className="col-md-4">
                            <p><span>Адрес:<br/></span> 693000, Сахалинская область, г. Южно-Сахалинск, ул. Транзитная 19</p>
                        </div>
                        <div className="col-md-2">
                            <p><span>Телефон:<br/></span> <a href="tel://84242466067">8 (4242) 46-60-67</a></p>
                        </div>
                        <div className="col-md-2">
                            <p><span>Факс:<br/></span> <a href="tel://84242466068">8 (4242) 46-60-68</a></p>
                        </div>
                        <div className="col-md-4">
                            <p><span>Email:<br/></span> <a href="mailto:accounting@vostokmarineservice.com">accounting@vostokmarineservice.com</a><br/><a
                                        href="mailto:supply@vostokmarineservice.com">supply@vostokmarineservice.com</a></p>
                        </div>
                    </div>
                </div>
            </section>
    </>
  );
}

export default Main;

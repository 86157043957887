import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

function Regioni() {
    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Регионы: Устойчивое развитие</title>
                <meta name='description' content='Кредиты на строительство и реконструкцию жилой и коммерческой недвижимости'/>
                <link rel="canonical" href="https://vostokmarineservice.com/rukava" />
            </Helmet>

            <section className="ftco-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <p>
                                <img src="assets/images/regioni.png" alt="" className="img-fluid"></img>
                            </p>
                            <h2 className="mb-3">Кредиты на строительство и реконструкцию жилой и коммерческой недвижимости</h2>
                            <div className="row mt-5">
                                <div className="col-md-4 heading-section">
                                    <span className="subheading subheading-with-line"><small className="pr-2 bg-white">Кредиторы</small></span>
                                </div>
                                <div className="col-md-4 d-flex align-self-stretch">
                                    <div className="media block-6 services d-block">
                                        <div className="icon d-flex">
                                            <img src="assets/images/sberbank.png"></img>
                                        </div>
                                        <div className="media-body mt-3">
                                            <ul>
                                                <li>жилая недвижимость</li>
                                                <li>коммерческая недвижимость</li>
                                                <a href="https://www.sberbank.ru/ru/legal/credits/award_regions">про Регионы на сайте банка</a>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 d-flex align-self-stretch">
                                    <div className="media block-6 services d-block">
                                        <div className="icon d-flex">
                                            <img src="assets/images/rosselhozbank.png"></img>
                                        </div>
                                        <div className="media-body mt-3">
                                            <ul>
                                                <li>сельское хозяйство</li>
                                                <li>АПК</li>
                                                <a href="https://www.rshb.ru/smallbusiness/konkurs/">про Регионы на сайте банка</a>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-4 heading-section">
                                    <span className="subheading subheading-with-line"><small className="pr-2 bg-white">Условия</small></span>
                                </div>
                                <div className="col-md-6 d-flex align-self-stretch">
                                    <div className="media block-6 services d-block">
                                        <div className="media-body mt-6">
                                            <ul>
                                                <li>Ставка: КСЦБ+1-3%.</li>
                                                <li>Сумма: от 50 млн. до 50 млрд. р.</li>
                                                <li>Срок: 3-15 лет</li>
                                                <li>Возможна отсрочка уплаты процентов на 9 мес.</li>
                                                <li>Отсрочка уплаты тела до 3 лет.</li>
                                                <li>От заявки до выдачи денег: 3-4 мес.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-4 heading-section">
                                    <span className="subheading subheading-with-line"><small className="pr-2 bg-white">Базовые требования</small></span>
                                </div>
                                <div className="col-md-6 d-flex align-self-stretch">
                                    <div className="media block-6 services d-block">
                                        <div className="media-body mt-6">
                                            <ul>
                                                <li>Отсутствие банкротств и уголовных преследований бенефициаров.</li>
                                                <li>Возможно создание нового юр.лица под проект.</li>
                                                <li>По группе компаний прошлый год по налоговой отчетности нет убытка.</li>
                                                <li>Кредит составляет 70% расходов проекта (85% для жилой недвижимости).</li>
                                                <li>Заемщик несет 30% расходов (15% для жилой).</li>
                                                <li>Засчитываются ранее понесенные расходы:
                                                    <ul>
                                                        <li>покупка или аренда земли</li>
                                                        <li>разрешения</li>
                                                        <li>документация</li>
                                                        <li>коммуникации</li>
                                                        <li>котлован</li>
                                                        <li>возведенные строения</li>
                                                        <li>иные расходы</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-4 heading-section">
                                    <span className="subheading subheading-with-line"><small className="pr-2 bg-white">Все кредитные деньги покрываются залогом</small></span>
                                </div>
                                <div className="col-md-6 d-flex align-self-stretch">
                                    <div className="media block-6 services d-block">
                                        <div className="media-body mt-6">
                                            <ul>
                                                <li>20% кредита — залог будущего строения</li>
                                                <li>70% — гарантия Корпорации МСП (входит в продукт)</li>
                                                <li>10% — деньги или недвижимость с дисконтом 50%</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-4 heading-section">
                                    <span className="subheading subheading-with-line"><small className="pr-2 bg-white">Контроль расходования кредитных средств</small></span>
                                </div>
                                <div className="col-md-6 d-flex align-self-stretch">
                                    <div className="media block-6 services d-block">
                                        <div className="media-body mt-6">
                                            <p>Через технического заказчика с опытом проведения проектов подобного размера и состоящего в СРО тех.заказчиков.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-4 heading-section">
                                    <span className="subheading subheading-with-line"><small className="pr-2 bg-white">Примеры выданных кредитов</small></span>
                                </div>
                                <div className="col-md-6 d-flex align-self-stretch">
                                    <div className="media block-6 services d-block">
                                        <div className="media-body mt-6">
                                            <p><Link to="https://www.dropbox.com/s/mej4i6yzs4jp663/winners.pdf?dl=0">Порядка 40% от выданного за 2017-2019 годы</Link></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5  mb-5">
                                <div className="col-md-4 heading-section">
                                    <span className="subheading subheading-with-line"><small className="pr-2 bg-white">Узнать подробнее</small></span>
                                </div>
                                <div className="col-md-4 d-flex align-self-stretch">
                                    <div className="media block-6 services d-block">
                                        <div className="icon d-flex icon-file-pdf-o">
                                            <Link className="ml-2" to="/files/presentation.pdf" target="_blank">Смотреть презентацию</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5  mb-5">
                                <div className="col-md-4 heading-section">
                                    <span className="subheading subheading-with-line"><small className="pr-2 bg-white">Заявка на участие</small></span>
                                </div>
                                <div className="col-md-4 d-flex align-self-stretch">
                                    <div className="media block-6 services d-block">
                                        <div className="icon d-flex icon-file-word-o">
                                            <Link className="ml-2" to="/files/form.docx" download="" target="_blank">Скачать форму заявки</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contacts" className="ftco-services bg-light">
                <div className="container">
                    <div className="row justify-content-start py-5">
                        <div className="col-md-8 heading-section">
                            <h2>Контакты</h2>
                        </div>
                        <div className="row justify-content-start py-5">
                            <div className="col-md-4 heading-section">
                                <Link to={"files/sertificate.pdf"} target="_blank"><img className="w-100" src="assets/images/sertificate.jpg" alt="sertificate"></img></Link>
                            </div>
                            <div className="col-md-8 pl-md-5">
                                <div className="pl-md-4 border-line">
                                    <div className="row">
                                        <div className="col-md-5 d-flex align-self-stretch">
                                            <div className="media block-6 services d-block">
                                                <div className="media-body p-2">
                                                    <h3 className="heading">Иван Гудков</h3>
                                                    <p>Региональный представитель Конкурса Ежегодная Общественная Премия «Регионы — Устойчивое развитие» по Сахалинской области.</p>
                                                    <div className="d-flex align-items-center icon-file-pdf-o">
                                                        <Link className="ml-2" to="/files/sertificate.pdf" target="_blank">Сертификат регионального представителя</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 d-flex align-self-stretch">
                                            <div className="media block-6 services d-block">
                                                <div className="media-body p-2">
                                                    <h3 className="heading">WhatsApp</h3>
                                                    <Link to="https://api.whatsapp.com/send?phone=79503010008" target="_blank">+7 950 301 00 08</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 d-flex align-self-stretch">
                                            <div className="media block-6 services d-block">
                                                <div className="media-body p-2">
                                                    <h3 className="heading">E-mail</h3>
                                                    <Link to="mailto:i.gudkov@vostokmarineservice.com">i.gudkov@vostokmarineservice.com</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section testimony-section">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 heading-section">
                            <span className="subheading subheading-with-line"><small className="pr-2 bg-light">Подробнее</small></span>
                            <h2 className="mb-4">Узнайте больше</h2>
                            <p>Информация по перечню документов и статусу инвестиционных проектов размещена на сайте Оргкомитета
                                <a href="http://www.infra-konkurs.ru" target="_blank">www.infra-konkurs.ru</a></p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Regioni